import React, { Suspense, useCallback } from "react";
import { Switch } from "react-router-dom";
import { useInterceptor } from "hooks/useInterceptor";
import { useAuth } from "safira-app/hooks/useAuth";
import Route from "./Route";
import { authenticatedRoutes } from "./routes";
import { Settings } from "luxon";
import LoadingComponent from "SocialNetwork/components/ProfileMidia/CreateAlbumModal/LoadingComponent";

const Routes: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { user, signOut, refreshToken } = useAuth();
  const getComponent = useCallback(
    (Component): React.ComponentType<React.PropsWithChildren<unknown>> => {
      return () => <Component {...{ user, signOut }} />;
    },
    [user, signOut],
  );
  useInterceptor(signOut, refreshToken);

  const userInfo = user as any;
  const isPerson = userInfo?.config?.default_profile_type === "person";

  Settings.defaultZone = user?.config?.default_timezone;

  return (
    <Switch>
      <Suspense fallback={<LoadingComponent />}>
        {authenticatedRoutes.map(({ Component, path, permissionsRequired }) => {
          const ComponentWithProps = getComponent(Component);

          return (
            <Route
              key={path}
              component={ComponentWithProps}
              path={path}
              isPrivate
              permissionsRequired={permissionsRequired}
              isPerson={isPerson}
              isAuthenticated={!!user}
              exact
            />
          );
        })}
      </Suspense>
    </Switch>
  );

  // TODO add 404
};

export default Routes;
