import React, { createContext, useEffect, useState } from "react";

export interface EndomarketingContextData {
  endomarketingList: any;
  setEndomarketingList: any;
  gavList: any;
  setGavList: any;
}

export const EndomarketingContext = createContext<EndomarketingContextData>({} as EndomarketingContextData);

export const EndomarketingProviderContext: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [endomarketingList, setEndomarketingList] = useState([]);
  const [gavList, setGavList] = useState([]);

  const context = {
    endomarketingList,
    setEndomarketingList,
    gavList,
    setGavList,
  };

  return <EndomarketingContext.Provider value={context}>{children}</EndomarketingContext.Provider>;
};
