import { lazy } from "react";

// Social Network
const Feed = lazy(() => import("SocialNetwork/pages/Feed"));
const Album = lazy(() => import("SocialNetwork/pages/Album"));
const Group = lazy(() => import("SocialNetwork/pages/Group"));
const Groups = lazy(() => import("SocialNetwork/pages/Groups"));
const Friends = lazy(() => import("SocialNetwork/pages/Friends"));
const Collaborators = lazy(() => import("SocialNetwork/pages/Collaborators"));
const ArticleCreate = lazy(() => import("SocialNetwork/pages/Article/Create"));
const ArticleSingleView = lazy(() => import("SocialNetwork/pages/Article/SingleView"));
const Articles = lazy(() => import("SocialNetwork/pages/Article/Articles"));
const Notifications = lazy(() => import("SocialNetwork/pages/Notifications"));
const Profile = lazy(() => import("SocialNetwork/pages/Profile"));
const ViewSinglePublication = lazy(() => import("SocialNetwork/pages/Publication"));

// Endomarketing
const DashboardEndomarketing = lazy(() => import("Endomarketing/pages/Dashboard"));

// Feedback
const Feedback_Dashboard = lazy(() => import("NewFeedback/pages/dashboard"));
const Feedback_Events = lazy(() => import("NewFeedback/pages/events"));

const PageNotFound = lazy(() => import("SocialNetwork/pages/404"));

export const authenticatedRoutes = [
  { path: "/404", Component: PageNotFound, permissionsRequired: [] },
  { path: "/", Component: Feed, permissionsRequired: [] },
  { path: "/pendencies", Component: Feed, permissionsRequired: [] },
  { path: "/feed", Component: Feed, permissionsRequired: [] },
  { path: "/groups", Component: Groups, permissionsRequired: [] },
  { path: "/group/:groupId/home", Component: Group, permissionsRequired: [] },
  { path: "/group/:groupId/members", Component: Group, permissionsRequired: [] },
  { path: "/group/:groupId/files", Component: Group, permissionsRequired: [] },
  { path: "/group/:groupId/about", Component: Group, permissionsRequired: [] },
  { path: "/group/:groupId/publication/:groupPublicationId", Component: Group, permissionsRequired: [] },
  { path: "/friends", Component: Friends, permissionsRequired: [] },
  { path: "/:nickname/collaborators", Component: Collaborators, permissionsRequired: [] },
  { path: "/p/:username", Component: Profile, permissionsRequired: [] },
  { path: "/publication/:id", Component: ViewSinglePublication, permissionsRequired: [] },
  { path: "/publication/:id/:commentType/:commentId", Component: ViewSinglePublication, permissionsRequired: [] },
  { path: "/article/form/:slug?", Component: ArticleCreate, permissionsRequired: [] },
  { path: "/article/view/:slug", Component: ArticleSingleView, permissionsRequired: [] },
  { path: "/articles", Component: Articles, permissionsRequired: [] },
  { path: "/articles/:username?", Component: Articles, permissionsRequired: [] },
  { path: "/p/:username/album/:id", Component: Album, permissionsRequired: [] },
  { path: "/notifications", Component: Feed, permissionsRequired: [] },
  { path: "/feed_company", Component: Feed, permissionsRequired: ["full_social_network"] },
  { path: "/friends_company", Component: Friends, permissionsRequired: ["full_social_network"] },
  { path: "/p_company/:id", Component: Profile, permissionsRequired: ["full_social_network"] },
  { path: "/publication_company/:id", Component: ViewSinglePublication, permissionsRequired: ["full_social_network"] },
  { path: "/article_company", Component: ArticleCreate, permissionsRequired: ["full_social_network"] },
  { path: "/article_company/:slug", Component: ArticleSingleView, permissionsRequired: ["full_social_network"] },
  { path: "/notifications_company", Component: Notifications, permissionsRequired: ["full_social_network"] },
  { path: "/feedback", Component: Feedback_Dashboard, permissionsRequired: [] },
  { path: "/feedback/events", Component: Feedback_Events, permissionsRequired: [] },
  { path: "/endomarketing/dashboard", Component: DashboardEndomarketing, permissionsRequired: [] },
  { path: "/endomarketing", Component: DashboardEndomarketing, permissionsRequired: [] },
];
