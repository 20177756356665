import React, { createContext } from "react";
import { useState } from "react";
import { useContext } from "react";
import api from "services/api";
import optionsGenerate from "../utils/optionsGenerate";
import { PublicationProps, PublicationResponseProps } from "interfaces/Publications";
import { links } from "safira-app/config/links";

interface PublicationType {
  hasNewPublication: boolean;
  publications: PublicationProps[];
  setPublications: React.Dispatch<React.SetStateAction<PublicationProps[]>>;
  getByProfileId: (profileId: string, page?: number, perpage?: number) => void;
  getPublicationId: (publicationId: string) => Promise<PublicationProps[]>;

  // PUBLICATION
  addPublication: (data: FormData, multiparte: boolean) => void;
  addPublicationGroup: any;
  sharePublication: (publication: PublicationProps) => Promise<any>;
  deletePublication: (publicationId: string) => Promise<void>;
  toggleLike: (publicationId: string) => Promise<boolean>;

  // COMMENT
  addComment: (publicationId: string, description: string) => Promise<PublicationResponseProps | undefined>;
  deleteComment: (commen_id: string) => Promise<any>;
  // removeComments: (commentsId: number) => Promise<boolean>;
  likeComment: (publication_id: string, comment_id: string) => Promise<void>;

  // SUBCOMMENT
  addSubComment: (publication_id: string, comment_id: string, description: string) => Promise<any>;
  deleteSubComment: (comment_id: string) => Promise<any>;
  likeSubComment: (publication_id: string, comment_id: string) => Promise<void>;

  shared: (publicationId: string, description?: string) => void;
  updateShared: (sharedId: string, description?: string) => Promise<boolean>;
  removeShared: (sharedId: string) => Promise<boolean>;
  hidePublication: (publicationId: string) => Promise<boolean>;
  removeHidePublication: (publicationId: string) => Promise<boolean>;
  remove: (publicationId: string) => void;
  clearHasNewPublication: () => void;
  informNewPublication: () => void;
}

const PublicationContext = createContext({} as PublicationType);

const baseUrl = links.api.social;

export const PublicationProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [publications, setPublications] = useState<PublicationProps[]>([]);
  const [hasNewPublication, setHasNewPublication] = useState(false);
  const { options, optionsJson, companyByUser } = optionsGenerate();

  function clearHasNewPublication() {
    setHasNewPublication(false);
  }

  function informNewPublication() {
    setHasNewPublication(true);
  }

  async function getByProfileId(profileId: string, page?: number, perpage?: number) {
    const response = await api
      .get(`${baseUrl}/profiles/publications/${profileId}?page=${page}&perPage=${perpage}`, options as any)
      .then((response: any) => {
        if (response?.status === 200) {
          setPublications(response.data);
        }

        return;
      });
  }

  async function getPublicationId(publicationId: string) {
    const response = await api.get(`${baseUrl}/publications/${publicationId}`);
    return response.data;
  }

  async function addPublication(data: any, multiparte = false) {
    const response = await api.post(`${baseUrl}/publications`, data, multiparte ? options : (optionsJson as any));
    return response;
  }

  async function addPublicationGroup(data: any, groupId: string, multipart = false) {
    const response = await api.post(
      `${baseUrl}/groups/${groupId}/publication`,
      data,
      multipart ? (options as any) : (optionsJson as any),
    );

    return response;
  }

  async function sharePublication(data: PublicationProps, multiparte = false) {
    const response = await api.post(`${baseUrl}/publications`, data, multiparte ? options : (optionsJson as any));

    if (response?.status === 200 || response?.status === 201) {
      setPublications([response.data, ...publications]);
      return response.data;
    }
  }

  async function deletePublication(publicationId: string) {
    const response = await api.delete(`${baseUrl}/publications/${publicationId}`, options as any);
    return await response.data;
  }

  async function toggleLike(publicationdId: string) {
    const response = await api.patch(`${baseUrl}/like/${publicationdId}`);

    if (response?.status === 200) return true;
    return false;
  }

  async function unLike(publicationdId: string) {
    const response = await api.post(`${baseUrl}/publications/${publicationdId}/like`, undefined, options as any);

    if (response?.status === 204) {
      return true;
    }

    return false;
  }

  //  COMMENTS

  async function addComment(publicationId: string, description: string) {
    const response = await api.post(`${baseUrl}/comment/${publicationId}`, { description });
    return (await response.data) as PublicationResponseProps;
  }

  async function deleteComment(commen_id: string) {
    const response = await api.delete(`${baseUrl}/comment/${commen_id}`);
    return await response.data;
  }

  async function likeComment(profile_id: string, comment_id: string) {
    const response = await api.patch(`${baseUrl}/like/${profile_id}`, { comment_id });
    return await response.data;
  }

  // SUBCOMMENTS

  async function addSubComment(publication_id: string, comment_id: string, description: string) {
    const response = await api.post(`${baseUrl}/comment/${publication_id}`, { comment_id, description });
    return response;
  }

  async function deleteSubComment(comment_id: string) {
    const response = await api.delete(`${baseUrl}/comment/${comment_id}`);
    return await response.data;
  }

  async function likeSubComment(profile_id: string, comment_id: string) {
    return await likeComment(profile_id, comment_id);
  }

  //** AJUDAR NO BACK END PARA RETORNA A PUBLICAÇÃO COMPLETA */
  async function shared(publicationId: string, description?: string) {
    const response = await api.post(
      `${baseUrl}/publications/shared`,
      {
        publication_id: publicationId,
        description,
      },
      optionsJson as any,
    );
  }

  async function updateShared(sharedId: string, description?: string) {
    const response = await api.put(
      `${baseUrl}/publications/shared/${sharedId}`,
      {
        description,
      },
      optionsJson as any,
    );

    if (response?.status === 200) {
      return true;
    }

    return false;
  }

  async function removeShared(sharedId: string) {
    const response = await api.delete(`${baseUrl}/publications/shared/${sharedId}`, options as any);

    if (response?.status === 204) {
      return true;
    }

    return false;
  }

  async function hidePublication(publicationId: string) {
    const response = await api.post(`${baseUrl}/publications/${publicationId}/hide`, undefined, options as any);

    if (response?.status === 200 || response?.status === 201) {
      return true;
    }

    return false;
  }

  async function removeHidePublication(publicationId: string) {
    const response = await api.delete(`${baseUrl}/publications/${publicationId}/hide`, options as any);

    if (response?.status === 200 || response?.status === 204) {
      return true;
    }

    return false;
  }

  async function remove(publicationId: string) {
    const response = await api.delete(`${baseUrl}/publications/${publicationId}`, options as any);

    if (response?.status === 204) {
      setPublications((old: any) => old.filter((item: any) => item.id !== publicationId));
    }
  }

  return (
    <PublicationContext.Provider
      value={{
        publications,
        setPublications,
        hasNewPublication,
        clearHasNewPublication,
        getByProfileId,
        getPublicationId,
        addPublication,
        addPublicationGroup,
        deletePublication,
        remove,
        toggleLike,
        sharePublication,

        // COMMENT
        addComment,
        deleteComment,
        likeComment,

        // SUBCOMMENT
        addSubComment,
        deleteSubComment,
        likeSubComment,

        removeHidePublication,
        hidePublication,
        removeShared,
        shared,
        updateShared,
        informNewPublication,
      }}
    >
      {children}
    </PublicationContext.Provider>
  );
};

export function usePublication() {
  return useContext(PublicationContext);
}
