import React, { useMemo } from "react";
import { Route as ReactDOMRoute } from "react-router-dom";
import { useSocialContext } from "SocialNetwork/context/SocialContext";
import { RouteProps } from "interfaces/RouteProps";

const Route: React.FC<React.PropsWithChildren<RouteProps>> = ({
  isPrivate = false,
  component: Component,
  isAuthenticated = false,
  isPerson = false,
  permissionsRequired,
  ...rest
}) => {
  const { myPermissions } = useSocialContext();
  const isComponentAccessible = useMemo(
    () => (isAuthenticated && isPrivate) || (!isAuthenticated && !isPrivate),
    [isAuthenticated, isPrivate],
  );

  if (myPermissions === "loading") {
    return <ReactDOMRoute exact {...rest} render={() => <></>} />;
  }

  return <ReactDOMRoute exact {...rest} render={() => (isComponentAccessible ? <Component /> : <></>)} />;
};

export default Route;
